import React, { useState } from 'react'
import './App.css';
import PlayList from './components/PlayList';
import Header from './components/Header';
import VideoPlayer from './components/VideoPlayer';
import Upload from './components/Upload';
import 'bootstrap/dist/css/bootstrap.css';
import { Provider } from 'react-redux';
import { store } from './store';
import background from './img/onwa2.jpg'

function App() {
  return (
    <div className='container' style={{
      backgroundImage:`url(${background})`, 
      backgroundPosition: "center", 
      backgroundColor:"#000", 
      backgroundRepeat: "no-repeat",
      }}>
      <Provider store={store}>        
        <Header/>
        <VideoPlayer/>
        <PlayList />
        <Upload />
        <div className='footer'></div>
      </Provider>
    </div>
  );
}

export default App;


/*
import { Stack } from "expo-router";
import { Provider } from "react-redux";
import { store } from '../../store'

export default function Layout() {
    return (
    <Provider store={store}>
      <Stack  screenOptions={{ headerShown: false }}> 
      <Stack.Screen name="Invoice" />
      <Stack.Screen name="Order" />
      <Stack.Screen name="Home" />
    </Stack>
  </Provider>
  );
}
*/
