import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../App.css'
import { setIsUpload, selectIsUpload } from '../slices/videoSlice';
import { useDispatch, useSelector } from 'react-redux';

const Header = () => {
  const dispatch = useDispatch();
  const isUplaod = useSelector(selectIsUpload)

  const addVideo = () => {
    const pkey = window.prompt("Please enter passkey");
    if(pkey === "ify2910220MASTER"){
        dispatch(setIsUpload(!isUplaod))
    } 
    
  }
  return (
    <div className='header'>
        <h2>Adieu Onwa Ogidi!</h2>
        <button onClick={() => addVideo()} style={{border: "none", padding: "10px",backgroundColor: "orange",fontSize: "12px", color: "#fff"}}>Add Video</button>
    </div>
  )
}

export default Header;