import React, { useState } from 'react';
import axios from 'axios';
import '../App.css'
import { useSelector } from 'react-redux';
import { selectIsUpload } from '../slices/videoSlice';

const Upload = () => {
  const [file, setFile] = useState();
  const [uploadProgress, setUploadProgress] = useState(0);
  const [caption, setCaption] = useState('');

  const isUplaod = useSelector(selectIsUpload)

  function handleChange(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    event.preventDefault();
    const url = '/videos';
    const formData = new FormData();
    formData.append('file', file);
    console.log(caption)
    formData.append('caption', caption)

    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
      onUploadProgress: function(progressEvent) {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
        setUploadProgress(percentCompleted);
      }
    };

    axios.post(url, formData, config)
    .then((response) => {
    console.log(response.data);
    })
    .catch((error) => {
    console.error("Error uploading file: ", error);
    });
  }

  return (
    <>
        { isUplaod && 
        <div className='uploadArea'>
            <form onSubmit={handleSubmit}>
                <div>
                    <input type='text' placeholder='caption' className='caption' required onChange={(e) => setCaption(e.target.value)}/>
                </div>
                <div>
                    <input type="file" onChange={handleChange} style={{color:"#fff"}}/>
                </div> 
                <div style={{textAlign: "center"}}>
                    <button type="submit" className='uploadbtn'>Upload</button>
                </div>     
                <div>
                    <progress className='progress' value={uploadProgress} max="100"></progress>
                </div>
            </form>
        </div>
        }
    </>
  );
}

export default Upload;


// function App() {
//   const [files, setFiles] = useState([]);
//   const [uploadedFiles, setUploadedFiles] = useState([]);

//   function handleMultipleChange(event) {
//     setFiles([...event.target.files]);
//   }

//   function handleMultipleSubmit(event) {
//     event.preventDefault();
//     const url = 'http://localhost:3000/uploadFiles';
//     const formData = new FormData();
//     files.forEach((file, index) => {
//       formData.append(`file${index}`, file);
//     });

//     const config = {
//       headers: {
//         'content-type': 'multipart/form-data',
//       },
//     };

//     axios.post(url, formData, config)
//       .then((response) => {
//         console.log(response.data);
//         setUploadedFiles(response.data.files);
//       })
//       .catch((error) => {
//         console.error("Error uploading files: ", error);
//       });
//   }

//   return (
//     <div className="App">
//       <form onSubmit={handleMultipleSubmit}>
//         <h1>React Multiple File Upload</h1>
//         <input type="file" multiple onChange={handleMultipleChange} />
//         <button type="submit">Upload</button>
//       </form>
//       {uploadedFiles.map((file, index) => (
//         <img key={index} src={file} alt={`Uploaded content ${index}`} />
//       ))}
//     </div>
//   );
// }