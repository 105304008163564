import React, { useRef, useEffect} from 'react'
import '../App.css'
import { selectVideoId, selectVideoTitle } from '../slices/videoSlice';
import { useSelector } from 'react-redux';

function VideoPlayer() { 
  const videoRef = useRef(null);
  const videoId = useSelector(selectVideoId);
  const videoTitle = useSelector(selectVideoTitle)


  useEffect(() => {
    if(videoRef.current) {
      videoRef.current.pause()
      videoRef.current.removeAttribute('src')
      videoRef.current.load()
    }
  },[videoId])

  return (
    <>
    { videoId && 
      <div className='videoArea'>
        <video ref={videoRef} className='videoPlayer' controls autoPlay>
          <source src={`/videos/${videoId}`}></source>
          Sorry, this browser cannot play video
        </video>      
        {videoTitle && <h5 style={{
          color: "#eee", 
          padding: "10px 5px", 
          backgroundColor: "rgba(0,0,0,0.6)",
          marginTop: "-10px",
          textAlign: "center"
        }}>{videoTitle}</h5>
        }
      </div>
    }
    </>
  )
}

export default VideoPlayer;