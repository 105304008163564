import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import '../App.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPlay } from '@fortawesome/free-solid-svg-icons'
import axios from "axios";
import { setVideoId, setVideoTitle, selectIsUpload } from '../slices/videoSlice';
import { useDispatch, useSelector } from 'react-redux';


const PlayList = () => {
    const [videos, setVideos] = useState([]);
    const isUplaod = useSelector(selectIsUpload);
    const dispatch = useDispatch()

    const getVideos = () => {    
        axios.get('/videos')
        .then((response) => {
            console.log(response.data);
            setVideos(response.data)
        })
        .catch((error) => {
            console.error("Error uploading file: ", error);
        });  
    }

    useEffect(() => {
        getVideos()
    },[])

    const playVideo = (videoId, title) => {
        dispatch(setVideoId(videoId));
        dispatch(setVideoTitle(title))
    }

    return (
        <div className='listArea'>
            <div className='listView'></div>            
            { videos && !isUplaod &&
                videos.map(({title, id, _id}) => (
                    <div key={id}>
                        <div className='listView' onClick={() => playVideo(_id,title)}>
                            <button><FontAwesomeIcon icon={faPlay} color='#fff'/></button>
                            <h4>{title}</h4>
                            {/* <img src={background} /> */}
                        </div>
                    </div>
                ))
                
            }
        </div>
    )
}

export default PlayList