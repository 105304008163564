import { configureStore } from '@reduxjs/toolkit'
import videoSlice from './slices/videoSlice.js'


export const store = configureStore({
  reducer: {
    video: videoSlice
  },
})

