import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    videoId: "",
    videoTitle: "",
    isUpload: false
}

export const videoSlice = createSlice({
    name: "video",
    initialState,
    reducers: {      
        setVideoId: (state,action) => {
            state.videoId = action.payload;
        },
        setVideoTitle: (state,action) => {
            state.videoTitle = action.payload;
        },
        setIsUpload: (state,action) => {
            state.isUpload = action.payload;
        }
    }
});

export const {    
    setVideoId,
    setVideoTitle,
    setIsUpload
} = videoSlice.actions;


export const selectVideoId = (state) => state.video.videoId;
export const selectVideoTitle = (state) => state.video.videoTitle;
export const selectIsUpload = (state) => state.video.isUpload;

export default videoSlice.reducer;